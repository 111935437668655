require('@/plugins/datejs/date-de-DE');

export const germanDate = (date) => {
  if (!date.includes(' | ')) {
    const splitDates = date.split('-');
    const dateParseFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
    return Date.parse(dateParseFormat).toString('d.MMMM yyyy');
  }
  const splitTwoDates = date.split(' | ');
  const splitDatesOne = splitTwoDates[0].split('-');
  const splitDatesTwo = splitTwoDates[1].split('-');
  const dateParseFormatFrom = `${splitDatesOne[0]}/${parseInt(splitDatesOne[2])}/${parseInt(splitDatesOne[1])}`; // month/day/year - 7/1/2004
  const dateParseFormatTo = `${splitDatesTwo[0]}/${parseInt(splitDatesTwo[2])}/${parseInt(splitDatesTwo[1])}`; // month/day/year - 7/1/2004
  return Date.parse(dateParseFormatFrom).toString('d.MMM yyyy') + ' - ' + Date.parse(dateParseFormatTo).toString('d.MMM yyyy');
};

export const germanWeekdayName = (date) => {
  const splitDates = date.split('-');
  const dateParseFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
  return Date.parse(dateParseFormat).toString('dddd');
};

export const germanCompleteDateTime = (date) => {
  const splitDates = date.split('-');
  const dateParseFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
  return Date.parse(dateParseFormat).toString('dddd, d. MMMM yyyy');
};

export const germanDateForSearch = (date) => {
  const splitDates = date.split('-');
  const dateParseFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
  return Date.parse(dateParseFormat).toString('d MMMM yyyy').toLowerCase();
};