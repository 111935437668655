<template>
  <div class="admin-news-page custom-container">
    <div class="header-wrapper">
      <h4 class="title">
        News
      </h4>
      <div class="d-flex">
        <b-btn
          size="sm"
          class="mr-3"
          @click="onPreview"
        >
          Preview Active News
        </b-btn>
        <b-btn
          variant="tab-orange"
          @click="onAddingState"
        >
          ADD
        </b-btn>
      </div>
    </div>
    <b-tabs
      v-model="tabIndex"
      content-class="mt-3"
    >
      <b-tab
        v-for="(tabName, index) in tabNames"
        :key="`newsTab${index}`"
        :title="tabName"
        @click="onClickTab(index)"
      />
    </b-tabs>

    <div v-show="isAddingState">
      <b-btn
        size="sm"
        class="mb-2"
        @click="clearAddingState"
      >
        Back
      </b-btn>
      <p><strong>Information <span style="color: red;">*</span>:</strong></p>
      <div
        v-if="tabIndex === 0"
        class="notes"
      >
        <p>
          Notes:
        </p>
        <ul>
          <li>Link should always contains <strong>https://</strong> or <strong>http://</strong> at front.</li>
          <li>For Arena, h1 and h2 will display the letter for learner font.</li>
          <li>For Arena, font will be bold as a default.</li>
        </ul>
      </div>
      <div
        v-else
        class="notes"
      >
        <p>
          Notes:
        </p>
        <ul>
          <li>Link should always contains <strong>https://</strong> or <strong>http://</strong> at front.</li>
        </ul>
      </div>
      <quill-editor
        ref="editor"
        v-model="editorContent"
        :options="editorOption"
        :class="[`tab-${tabIndex} mb-3`]"
      />
      <p
        v-if="isEditorError"
        class="my-2 text-danger"
      >
        <strong>
          Content can't be empty
        </strong>
      </p>
      <b-row class="booking-form">
        <b-col md="6">
          <b-form-group
            :state="isErrorForm['inputStartDate']"
            :invalid-feedback="initErrorMsg['inputStartDate']"
            description="Wählen Sie für einen Tag dasselbe Start- und Enddatum aus"
          >
            <p>
              <strong>Start 
                <span style="color: red;">*</span>:</strong>
            </p>
            <b-form-datepicker
              id="input-start-date"
              v-model="inputForm.inputStartDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              locale="de"
              @input="isErrorForm['inputStartDate'] = null"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :state="isErrorForm['inputEndDate']"
            :invalid-feedback="initErrorMsg['inputEndDate']"
            description="Wählen Sie für einen Tag dasselbe Start- und Enddatum aus"
          >
            <p><strong>End <span style="color: red;">*</span>:</strong></p>
            <b-form-datepicker
              id="input-end-date"
              v-model="inputForm.inputEndDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :min="inputForm.inputStartDate"
              locale="de"
              @input="isErrorForm['inputEndDate'] = null"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-btn
        v-if="tabIndex === 0"
        variant="outline-primary"
        class="my-3"
        @click="isAdditionalInfo = !isAdditionalInfo"
      >
        {{ isAdditionalInfo ? 'Hide additional info' : 'See additional info' }}
      </b-btn>
      <div
        v-if="isAdditionalInfo && tabIndex === 0"
        class="text-input-group mb-3"
      >
        <b-form-group>
          <p><strong>Heading:</strong></p>
          <b-form-input
            id="input-heading"
            v-model="inputAdditionalForm.heading"
          />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              :state="isErrorForm['inputImageFile']"
              :invalid-feedback="initErrorMsg['inputImageFile']"
            >
              <p><strong>Image file:</strong></p>
              <p v-if="editingItem && editingItem.imageProvider && !isRemovingExistingImage">
                <a
                  :href="editingItem.image"
                  target="_blank"
                >Image</a>
              </p>
              <b-form-file
                ref="imageFileRef"
                v-model="inputAdditionalForm.imageFile"
                accept="image/jpeg, image/png, image/svg+xml"
                placeholder="Choose an image or drop it here..."
                drop-placeholder="Drop file here..."
                @input="insertImageFile"
              />
              <p
                v-if="editingItem && editingItem.imageProvider && !isRemovingExistingImage"
                style=""
              >
                <a
                  style="color: red; text-decoration: underline; cursor: pointer;"
                  @click="removeExitingImage"
                >
                  <small>Remove image</small>
                </a>
              </p>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <p style="text-align: center;">
              or
            </p>
          </b-col>
          <b-col>
            <b-form-group
              :state="isErrorForm['inputImageUrl']"
              :invalid-feedback="initErrorMsg['inputImageUrl']"
            >
              <p><strong>Image url:</strong></p>
              <b-form-input
                id="input-image-url"
                v-model="inputAdditionalForm.imageUrl"
                @input="insertImageUrl"
                @change="validateImageUrl"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          :state="isErrorForm['inputLinkUrl']"
          :invalid-feedback="initErrorMsg['inputLinkUrl']"
        >
          <p><strong>Link url:</strong></p>
          <b-form-input
            id="input-link-url"
            v-model="inputAdditionalForm.linkUrl"
            @change="validateLinkUrl"
          />
        </b-form-group>
      </div>
      <div class="d-flex justify-content-between mt-1">
        <b-btn
          size="sm"
          @click="clearAddingState"
        >
          Back
        </b-btn>
        <div class="d-flex">
          <b-btn
            variant="outline-primary"
            class="mr-3"
            @click="onAddingPreview"
          >
            Preview
          </b-btn>
          <b-btn
            variant="tab-orange"
            @click="onSubmitForm"
          >
            speichern
          </b-btn>
        </div>
      </div>
    </div>
    <div v-show="!isAddingState">
      <div v-if="loadedItems.length === 0">
        <h5 class="text-center mb-3">
          No added data.
        </h5>
        <div class="text-center">
          <b-btn
            variant="tab-orange"
            @click="onAddingState"
          >
            ADD
          </b-btn>
        </div>
      </div>
      <template v-else>
        <template v-if="sortedActiveNews.length">
          <div
            v-for="(item, index) in sortedActiveNews"
            :key="`newsItem${index}`"
            class="d-flex flex-column content-box"
          >
            <div class="d-flex justify-content-between mb-3">
              <div>
                <p class="mb-2">
                  Start: <strong>{{ item.startDate | germanDate }}</strong>
                </p>
                <p class="mb-2">
                  End: <strong>{{ item.endDate | germanDate }}</strong>
                </p>
                <p class="mb-2">
                  Status: <span class="active-status">Active</span>
                </p>
                <p class="mb-2">
                  Heading: <strong>{{ item.heading }}</strong>
                </p>
                <p>
                  Link url: <a
                    v-if="item.linkUrl"
                    :href="item.linkUrl"
                    target="_blank"
                  >{{ item.linkUrl || '' }}</a>
                </p>
              </div>
              <div class="d-flex flex-column">
                <b-btn
                  size="sm"
                  class="mb-2"
                  variant="outline-info"
                  @click="onEdit(item)"
                >
                  Edit
                </b-btn>
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  @click="onDelete(item)"
                >
                  Delete
                </b-btn>
              </div>
            </div>
            <b-row class="content">
              <b-col
                v-if="item.image"
                cols="3"
              >
                <img :src="item.image">
              </b-col>
              <b-col :cols="item.image ? 9 : 12">
                <div
                  class="editor-box ql-editor"
                  :class="[`tab-${tabIndex}`]"
                  v-html="item.information"
                />
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="sortedUpcomingNews.length">
          <hr>
          <p class="mb-2">
            <strong>Upcoming news</strong>
          </p>
          <div
            v-for="(item, index) in sortedUpcomingNews"
            :key="`newsNotItem${index}`"
            class="d-flex flex-column content-box inactive-content"
          >
            <div class="d-flex justify-content-between mb-3">
              <div>
                <p
                  v-if="isUncomingContent(item.startDate)"
                  class="mb-2"
                >
                  <strong><span style="font-style: italic;">Note:</span></strong> Content will be displayed on {{ item.startDate | germanDate }}
                </p>
                <p
                  v-else
                  class="mb-2"
                >
                  <strong><span style="font-style: italic;">Note:</span></strong> Content is already passed.
                </p>
                <p class="mb-2">
                  Start: <strong>{{ item.startDate | germanDate }}</strong>
                </p>
                <p class="mb-2">
                  End: <strong>{{ item.endDate | germanDate }}</strong>
                </p>
                <p class="mb-2">
                  Status: <span class="not-active-status">Not Active</span>
                </p>
                <p class="mb-2">
                  Heading: <strong>{{ item.heading }}</strong>
                </p>
                <p>
                  Link url: <a
                    v-if="item.linkUrl"
                    :href="item.linkUrl"
                    target="_blank"
                  >{{ item.linkUrl || '' }}</a>
                </p>
              </div>
              <div class="d-flex flex-column">
                <b-btn
                  size="sm"
                  class="mb-2"
                  variant="outline-info"
                  @click="onEdit(item)"
                >
                  Edit
                </b-btn>
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  @click="onDelete(item)"
                >
                  Delete
                </b-btn>
              </div>
            </div>
            <b-row class="content">
              <b-col
                v-if="item.image"
                cols="3"
              >
                <img :src="item.image">
              </b-col>
              <b-col :cols="item.image ? 9 : 12">
                <div
                  class="editor-box ql-editor"
                  :class="[`tab-${tabIndex}`]"
                  v-html="item.information"
                />
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-if="sortedPassedNews.length">
          <hr>
          <p class="mb-2">
            <strong>Expired news</strong>
          </p>
          <div
            v-for="(item, index) in sortedPassedNews"
            :key="`newsNotItem${index}`"
            class="d-flex flex-column content-box inactive-content"
          >
            <div class="d-flex justify-content-between mb-3">
              <div>
                <p
                  v-if="isUncomingContent(item.startDate)"
                  class="mb-2"
                >
                  <strong><span style="font-style: italic;">Note:</span></strong> Content will be displayed on {{ item.startDate | germanDate }}
                </p>
                <p
                  v-else
                  class="mb-2"
                >
                  <strong><span style="font-style: italic;">Note:</span></strong> Content is already passed.
                </p>
                <p class="mb-2">
                  Start: <strong>{{ item.startDate | germanDate }}</strong>
                </p>
                <p class="mb-2">
                  End: <strong>{{ item.endDate | germanDate }}</strong>
                </p>
                <p class="mb-2">
                  Status: <span class="not-active-status">Not Active</span>
                </p>
                <p class="mb-2">
                  Heading: <strong>{{ item.heading }}</strong>
                </p>
                <p>
                  Link url: <a
                    v-if="item.linkUrl"
                    :href="item.linkUrl"
                    target="_blank"
                  >{{ item.linkUrl || '' }}</a>
                </p>
              </div>
              <div class="d-flex flex-column">
                <b-btn
                  size="sm"
                  class="mb-2"
                  variant="outline-info"
                  @click="onEdit(item)"
                >
                  Edit
                </b-btn>
                <b-btn
                  size="sm"
                  variant="outline-danger"
                  @click="onDelete(item)"
                >
                  Delete
                </b-btn>
              </div>
            </div>
            <b-row class="content">
              <b-col
                v-if="item.image"
                cols="3"
              >
                <img :src="item.image">
              </b-col>
              <b-col :cols="item.image ? 9 : 12">
                <div
                  class="editor-box ql-editor"
                  :class="[`tab-${tabIndex}`]"
                  v-html="item.information"
                />
              </b-col>
            </b-row>
          </div>
        </template>
        <div class="d-flex justify-content-center mt-4">
          <b-btn
            variant="tab-orange"
            @click="onAddingState"
          >
            ADD
          </b-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/admin-news.scss";
</style>


<script>
require('@/plugins/datejs/date-de-DE');
import { mapState } from 'vuex';
import { loader } from '@/plugins/loader';
import { germanDate } from '@/helpers/filters/germanDate';

import { AdminInformation } from '@/services';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
import { isObjectEmpty } from '@/helpers';

const FETCH_KEY = ['homepage_arena', 'adventure_golf'];

const DEFAULT_COLORS = [
  '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
  '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
  '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
  '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
  '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
];

const toolbarOptions = [
  ['bold', 'italic', 'underline'],        // toggled buttons

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'align': [] }],
  [{ 'list': 'bullet' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

  [{ 'color': ['#F09009', '#ec69a4', '#95C11F', '#64CC97', '#A787ED', ...DEFAULT_COLORS] }, { 'background': ['#F09009', '#ec69a4', '#95C11F', '#64CC97', '#A787ED', ...DEFAULT_COLORS] }],          // dropdown with defaults from theme

  ['link'],
  ['image'],
];

export default {
  components: {
    quillEditor,
  },
  filters: { germanDate },
  data() {
    return {
      filter: '',
      today: '',
      isLoading: false,
      tabIndex: 0,
      tabNames: ['Arena', 'Adventure Golf'],
      loadedItems: [],
      isAddingState: false,
      editorContent: '',
      editingItem: null,
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: this.imageHandler,
            },
          },
        },
      },

      inputForm: {
        inputStartDate: '',
        inputEndDate: '',
      },
      inputAdditionalForm: {
        heading: '',
        imageUrl: '',
        imageFile: null,
        linkUrl: '',
      },
      isErrorForm: {
        inputStartDate: null,
        inputEndDate: null,
        inputImageFile: null,
        inputImageUrl: null,
        inputLinkUrl: null,
      },
      initErrorMsg: {
        inputStartDate: '',
        inputEndDate: '',
        inputImageFile: '',
        inputImageUrl: '',
        inputLinkUrl: '',
      },
      isEditorError: false,
      isAdditionalInfo: false,
      isRemovingExistingImage: false,
      isFromPreview: false,
      isFileFromPreview: false,
    };
  },
  computed: {
    ...mapState('admin', ['savedNewsEditState']),

    sortedActiveNews() {
      if (this.loadedItems.length === 0) {
        return [];
      }
      const result = this.loadedItems.filter((item) => {
        const startDate = item.startDate.split('-');
        const startDateSlashFormat = `${startDate[0]}/${parseInt(startDate[2])}/${parseInt(startDate[1])}`;
        const startDateParseFormat = Date.parse(startDateSlashFormat);
        const endDate = item.endDate.split('-');
        const endDateSlashFormat = `${endDate[0]}/${parseInt(endDate[2])}/${parseInt(endDate[1])}`;
        const endDateParseFormat = Date.parse(endDateSlashFormat);
        const todayFormatted = Date.today().toString('yyyy-MM-dd');

        return Date.today().between(startDateParseFormat, endDateParseFormat) ||
                    todayFormatted === item.startDate ||
                    todayFormatted === item.endDate;
      });
      return result.sort((a, b) => {
        const startDateA = a.startDate.split('-');
        const startDateSlashFormatA = `${startDateA[0]}/${parseInt(startDateA[2])}/${parseInt(startDateA[1])}`;
        const startDateParseFormatA = Date.parse(startDateSlashFormatA);
        const startDateB = b.startDate.split('-');
        const startDateSlashFormatB = `${startDateB[0]}/${parseInt(startDateB[2])}/${parseInt(startDateB[1])}`;
        const startDateParseFormatB = Date.parse(startDateSlashFormatB);
        return startDateParseFormatB.compareTo(startDateParseFormatA);
      });
    },
    sortedUpcomingNews() {
      if (this.loadedItems.length === 0) {
        return [];
      }
      const getActiveItemIds = this.sortedActiveNews.map((item) => item.identification);
      const result = this.loadedItems.filter((item) => {
        const today = new Date();
        const startDate = new Date(item.startDate);
        return !getActiveItemIds.includes(item.identification)
                && startDate.getTime() >= today.getTime();
      });

      return result.sort((a, b) => {
        const startDateA = a.startDate.split('-');
        const startDateSlashFormatA = `${startDateA[0]}/${parseInt(startDateA[2])}/${parseInt(startDateA[1])}`;
        const startDateParseFormatA = Date.parse(startDateSlashFormatA);
        const startDateB = b.startDate.split('-');
        const startDateSlashFormatB = `${startDateB[0]}/${parseInt(startDateB[2])}/${parseInt(startDateB[1])}`;
        const startDateParseFormatB = Date.parse(startDateSlashFormatB);
        return startDateParseFormatA.compareTo(startDateParseFormatB);
      });
    },
    sortedPassedNews() {
      if (this.loadedItems.length === 0) {
        return [];
      }
      const getActiveItemIds = this.sortedActiveNews.map((item) => item.identification);
      const result = this.loadedItems.filter((item) => {
        const today = new Date();
        const startDate = new Date(item.startDate);
        return !getActiveItemIds.includes(item.identification)
                && startDate.getTime() < today.getTime();
      });

      return result.sort((a, b) => {
        const startDateA = a.startDate.split('-');
        const startDateSlashFormatA = `${startDateA[0]}/${parseInt(startDateA[2])}/${parseInt(startDateA[1])}`;
        const startDateParseFormatA = Date.parse(startDateSlashFormatA);
        const startDateB = b.startDate.split('-');
        const startDateSlashFormatB = `${startDateB[0]}/${parseInt(startDateB[2])}/${parseInt(startDateB[1])}`;
        const startDateParseFormatB = Date.parse(startDateSlashFormatB);
        return startDateParseFormatB.compareTo(startDateParseFormatA);
      });
    },

    hasErrorInForm() {
      return Object.values(this.isErrorForm).some((val) => val === false) || this.isEditorError;
    },
  },
  created() {
    this.today = Date.today();
    if (!isObjectEmpty(this.savedNewsEditState)) {
      this.isAddingState = this.savedNewsEditState.isAddingState;
      this.editingItem = this.savedNewsEditState.editingItem;
      this.editorContent = this.savedNewsEditState.editorContent;
      this.inputForm = this.savedNewsEditState.inputForm;
      this.inputAdditionalForm = this.savedNewsEditState.inputAdditionalForm;
      const hasAdditionalInfo = Object.values(this.inputAdditionalForm).some((val) => val);
      this.isAdditionalInfo = hasAdditionalInfo;
      this.tabIndex = this.savedNewsEditState.tabIndex;
      this.isFromPreview = true;
      if (this.inputAdditionalForm.imageFile ||
      (this.editingItem.imageProvider && this.inputAdditionalForm.imageUrl)) {
        this.isRemovingExistingImage = true;
      }
    }

    setTimeout(() => {
      this.loadData();
    }, 100);
  },
  updated() {
    if (this.$refs.imageFileRef && this.inputAdditionalForm.imageFile && !this.isFileFromPreview && this.isFromPreview) {
      this.$nextTick(() => {
        this.isFileFromPreview = true;
        this.$refs.imageFileRef.setFiles([this.inputAdditionalForm.imageFile]);
      });
    }
  },
  methods: {
    imageHandler() {
      let range = this.$refs.editor.quill.getSelection();
      let value = prompt('What is the image URL');
      if(value){
        this.$refs.editor.quill.insertEmbed(range.index, 'image', value, 'user');
      }
    },

    async loadData() {
      loader.start();
      this.isLoading = true;
      let response;
      try {
        response = await AdminInformation.showNewsTicker({ type: FETCH_KEY[this.tabIndex] });
        if (response.status === 200) {
          this.loadedItems = [...response.data];
        } else {
          this.loadedItems = [];
        }
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      } finally {
        loader.stop();
      }
      return response;
    },
    onClickTab() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.loadData();
          this.resetForm();
        }, 100);
      });
    },
    onDelete(item) {
      loader.start();
      AdminInformation.removeNewsTicker({
        id: item.identification,
      }).then(() => {
        this.$bvModal.msgBoxOk('News has been deleted successfully', {
          title: 'Delete successful',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
        this.loadData().finally(() => {
          loader.stop();
        });
      }).catch((err) => {
        this.$bvModal.msgBoxOk(err, {
          title: 'Error',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
        loader.stop();
      });
    },
    onEdit(item) {
      this.isAddingState = true;
      this.editingItem = item;
      this.editorContent = item.information;
      this.inputForm = {
        inputStartDate: item.startDate,
        inputEndDate: item.endDate,
      };
      this.inputAdditionalForm = {
        heading: item.heading || '',
        imageUrl: item.image && !item.imageProvider ? item.image : '',
        imageFile: null,
        linkUrl: item.linkUrl || '',
      };
      const hasAdditionalInfo = Object.values(this.inputAdditionalForm).some((val) => val);
      this.isAdditionalInfo = hasAdditionalInfo;
    },
    onPreview() {
      this.$store.dispatch('admin/updatePreviewNews', this.sortedActiveNews);
      this.$store.dispatch('admin/updateSaveNewsEditState', null);
      if (this.tabIndex === 0) {
        this.$router.push({
          name: 'admin-news-preview-arena',
        });
      } else if (this.tabIndex === 1) {
        this.$router.push({
          name: 'admin-news-preview-golf',
        });
      }
    },
    onPreviewNotActive(item) {
      this.$store.dispatch('admin/updatePreviewNews', [item]);
      this.$store.dispatch('admin/updateSaveNewsEditState', {
        editingItem: this.editingItem,
        editorHeading: this.editorHeading,
        editorContent: this.editorContent,
        inputForm: this.inputForm,
        inputAdditionalForm: this.inputAdditionalForm,
        tabIndex: this.tabIndex,
        isAddingState: this.isAddingState,
      });
      if (this.tabIndex === 0) {
        this.$router.push({
          name: 'admin-news-preview-arena',
        });
      } else if (this.tabIndex === 1) {
        this.$router.push({
          name: 'admin-news-preview-golf',
        });
      }
    },
    clearAddingState() {
      this.$store.dispatch('admin/updatePreviewNews', []);
      this.$store.dispatch('admin/updateSaveNewsEditState', null);
      this.isAddingState = false;
      this.editingItem = null;
      this.isAdditionalInfo = false;
      this.isRemovingExistingImage = false;
    },
    onAddingState() {
      this.resetForm();
      this.isAddingState = true;
    },
    onAddingPreview() {
      this.$store.dispatch('admin/updatePreviewNews', [{
        heading: this.inputAdditionalForm.heading,
        image: this.inputAdditionalForm.imageUrl,
        linkUrl: this.inputAdditionalForm.linkUrl,
        information: this.editorContent,
        startDate: Date.today().toString('yyyy-MM-dd'),
        endDate: Date.today().toString('yyyy-MM-dd'),
      }]);
      this.$store.dispatch('admin/updateSaveNewsEditState', {
        editingItem: this.editingItem,
        editorContent: this.editorContent,
        inputForm: this.inputForm,
        inputAdditionalForm: this.inputAdditionalForm,
        tabIndex: this.tabIndex,
        isAddingState: this.isAddingState,
      });
      if (this.tabIndex === 0) {
        this.$router.push({
          name: 'admin-news-preview-arena',
        });
      } else if (this.tabIndex === 1) {
        this.$router.push({
          name: 'admin-news-preview-golf',
        });
      }
    },
    validateInputForm() {
      Object.keys(this.inputForm).forEach((key) => {
        if (this.inputForm[key] === '') {
          this.isErrorForm = {
            ...this.isErrorForm,
            [key]: false,
          };
          this.initErrorMsg = {
            ...this.initErrorMsg,
            [key]: 'Dieses Feld wird benötigt',
          };
        } else {
          this.isErrorForm = {
            ...this.isErrorForm,
            [key]: null,
          };
          this.initErrorMsg = {
            ...this.initErrorMsg,
            [key]: '',
          };
        }
      });
      this.isEditorError = this.$refs.editor.$el.innerText.replace(/[\s\n]/g, '').length === 0;
    },
    validateOverlappedDate() {
      let oldContentId = 0;
      const inputStartDate = new Date(this.inputForm.inputStartDate);
      const inputEndDate = new Date(this.inputForm.inputEndDate);
      this.loadedItems.forEach((item) => {
        const itemStartDate = new Date(item.startDate);
        const itemEndDate = new Date(item.endDate);
        if (!this.editingItem || (this.editingItem && item.identification !== this.editingItem.identification)) {
          const isOverlap = this.checkDateRangeOverlaps(itemStartDate, itemEndDate, inputStartDate, inputEndDate);
          if (isOverlap) {
            oldContentId = item.identification;
            return oldContentId;
          }
        }
      });
      return oldContentId;
    },
    checkDateRangeOverlaps(firstStartDate, firstEndDate, secondStartDate, secondEndDate) {
      try {
        if (firstStartDate.getTime() <= secondStartDate.getTime() && secondStartDate.getTime() <= firstEndDate.getTime()) return true; // b starts in a
        if (firstStartDate.getTime() <= secondEndDate.getTime() && secondEndDate.getTime() <= firstEndDate.getTime()) return true; // b ends in a
        if (secondStartDate.getTime() < firstStartDate.getTime() && firstEndDate.getTime() < secondEndDate.getTime()) return true; // a in b
        return false;
      } catch (err) {
        return false;
      }
    },
    onSubmitForm() {
      this.validateInputForm();
      if (this.inputAdditionalForm.imageFile) {
        this.validateImageFile();
      } else {
        this.validateImageUrl();
      }
      this.validateDate();
      this.validateLinkUrl();
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.hasErrorInForm) {
            return;
          }
          const overlappedContentId = this.validateOverlappedDate();
          if (this.editingItem === null) {
            if (overlappedContentId > 0) {
              this.$bvModal.msgBoxConfirm('Date is overlapped with existing news. Do you want do remove old content and replace with new content?', {
                title: 'Warning',
                okVariant: 'tab-orange',
                headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
                footerClass: 'p-2 border-top-0',
                centered: true,
              }).then((result) => {
                if (result) {
                  loader.start();
                  AdminInformation.removeNewsTicker({
                    id: overlappedContentId,
                  }).then(() => {
                    this.createNewsTicker();
                  });
                  return;
                }
              });
            } else {
              this.createNewsTicker();
            }
          } else {
            if (overlappedContentId > 0) {
              this.$bvModal.msgBoxConfirm('Date is overlapped with existing news. Do you want do remove old content and replace with new content?', {
                title: 'Warning',
                okVariant: 'tab-orange',
                headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
                footerClass: 'p-2 border-top-0',
                centered: true,
              }).then((result) => {
                if (result) {
                  AdminInformation.removeNewsTicker({
                    id: overlappedContentId,
                  }).then(() => {
                    this.updateNewsTicker();
                  });
                }
                return;
              });
            } else {
              this.updateNewsTicker();
            }
          }
        }, 100);
      });
    },
    createNewsTicker() {
      let params = {
        startDate: this.inputForm.inputStartDate + ' 00:00:00',
        endDate: this.inputForm.inputEndDate + ' 23:59:59',
        heading: this.inputAdditionalForm.heading,
        linkUrl: this.inputAdditionalForm.linkUrl,
        information: this.editorContent,
        pageConfig: FETCH_KEY[this.tabIndex],
      };
      if (this.inputAdditionalForm.imageFile) {
        params = {
          ...params,
          imageName: this.inputAdditionalForm.imageFile,
        };
      } else if (this.inputAdditionalForm.imageUrl) {
        params = {
          ...params,
          imageUrl: this.inputAdditionalForm.imageUrl,
        };
      }
      AdminInformation.createNewsTicker(params).then(() => {
        this.$bvModal.msgBoxOk('News has been added successfully', {
          title: 'Add successful',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
        this.loadData()
          .then(() => {
            this.resetForm();
          });
      }).catch((err) => {
        this.$bvModal.msgBoxOk(err, {
          title: 'Error',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      }).finally(() => {
        loader.stop();
      });
    },
    updateNewsTicker() {
      loader.start();
      let params = {
        id: this.editingItem.identification,
        startDate: this.inputForm.inputStartDate + ' 00:00:00',
        endDate: this.inputForm.inputEndDate + ' 23:59:59',
        heading: this.inputAdditionalForm.heading,
        linkUrl: this.inputAdditionalForm.linkUrl,
        information: this.editorContent,
        pageConfig: FETCH_KEY[this.tabIndex],
      };
      if (this.inputAdditionalForm.imageFile) {
        params = {
          ...params,
          imageName: this.inputAdditionalForm.imageFile,
        };
      } else if (this.inputAdditionalForm.imageUrl) {
        params = {
          ...params,
          imageUrl: this.inputAdditionalForm.imageUrl,
        };
      } else if (this.isRemovingExistingImage &&
                !this.inputAdditionalForm.imageFile &&
                !this.inputAdditionalForm.imageUrl) {
        params = {
          ...params,
          imageUrl: '',
        };
      } else if (!this.editingItem.imageProvider &&
                this.editingItem.image &&
                !this.inputAdditionalForm.imageUrl &&
                !this.inputAdditionalForm.imageFile) {
        params = {
          ...params,
          imageUrl: '',
        };
      }
      AdminInformation.updateNewsTicker(params).then(() => {
        this.$bvModal.msgBoxOk('News has been updated successfully', {
          title: 'Update successful',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
        this.loadData()
          .then(() => {
            this.resetForm();
          });
      }).catch((err) => {
        this.$bvModal.msgBoxOk(err, {
          title: 'Error',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
        loader.stop();
      });
    },
    resetForm() {
      this.clearAddingState();
      this.editorContent = '';
      this.inputForm = {
        inputStartDate: Date.today().toString('yyyy-MM-dd'),
        inputEndDate: '',
      };
      this.inputAdditionalForm = {
        heading: '',
        imageUrl: '',
        imageFile: null,
        linkUrl: '',
      };
    },
    isUncomingContent(inputDateText) {
      const inputDate = new Date(inputDateText);
      const today = new Date();
      return today.getTime() < inputDate.getTime();
    },
    insertImageUrl() {
      this.inputAdditionalForm.imageFile = null;
      this.isRemovingExistingImage = true;
      this.isErrorForm = {
        ...this.isErrorForm,
        inputImageFile: null,
      };
      this.initErrorMsg.inputImageFile = '';
    },
    insertImageFile() {
      if (this.inputAdditionalForm.imageFile) {
        this.inputAdditionalForm.imageUrl = '';
        this.isRemovingExistingImage = true;
        this.isErrorForm = {
          ...this.isErrorForm,
          inputImageUrl: null,
        };
        this.initErrorMsg.inputImageUrl = '';
        this.validateImageFile();
      }
    },
    removeExitingImage() {
      this.isRemovingExistingImage = true;
    },
    validateImageUrl() {
      this.isErrorForm = {
        ...this.isErrorForm,
        inputImageUrl: null,
      };
      this.initErrorMsg.inputImageUrl = '';
      if (this.inputAdditionalForm.imageUrl) {
        const image = new Image();
        image.src = this.inputAdditionalForm.imageUrl;
        const isImageUrlError = () => {
          this.isErrorForm = {
            ...this.isErrorForm,
            inputImageUrl: false,
          };
          this.initErrorMsg.inputImageUrl = 'Invalid image url';
        };
        image.onerror = () => {
          isImageUrlError();
        };
      }
    },
    validateImageFile() {
      this.isErrorForm = {
        ...this.isErrorForm,
        inputImageFile: null,
      };
      this.initErrorMsg.inputImageFile = '';
      if (this.inputAdditionalForm.imageFile) {
        if (this.inputAdditionalForm.imageFile.size > 5000000) {
          this.isErrorForm = {
            ...this.isErrorForm,
            inputImageFile: false,
          };
          this.initErrorMsg.inputImageFile = 'File size must be less than 5 MB.';
        } else if (!['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'].includes(this.inputAdditionalForm.imageFile.type)) {
          this.isErrorForm.inputImageFile = false;
          this.initErrorMsg.inputImageFile = 'Image must be in .jpg, .jpeg, .png, .svg';
        }
      }
    },
    validateLinkUrl() {
      this.isErrorForm = {
        ...this.isErrorForm,
        inputLinkUrl: null,
      };
      this.initErrorMsg.inputLinkUrl = '';
      if (this.inputAdditionalForm.linkUrl) {
        let re = new RegExp('^(http|https)://', 'i');
        if (!re.test(this.inputAdditionalForm.linkUrl)) {
          this.isErrorForm = {
            ...this.isErrorForm,
            inputLinkUrl: false,
          };
          this.initErrorMsg.inputLinkUrl = 'Invalid link format.';
        }
      }
    },
    validateDate() {
      this.isErrorForm = {
        ...this.isErrorForm,
        inputStartDate: null,
      };
      this.initErrorMsg = {
        ...this.initErrorMsg,
        inputStartDate: '',
      };
      if (this.inputForm.inputStartDate && this.inputForm.inputEndDate) {
        const startDate = new Date(this.inputForm.inputStartDate);
        const endDate = new Date(this.inputForm.inputEndDate);
        if (endDate.getTime() < startDate.getTime()) {
          this.isErrorForm = {
            ...this.isErrorForm,
            inputStartDate: false,
          };
          this.initErrorMsg = {
            ...this.initErrorMsg,
            inputStartDate: 'Start date cannot come after end date.',
          };
        }
      }
    },
  },
};
</script>